@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 1rem;
  min-height: 100vh;
  @apply bg-gray-800;
  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.technology-icons {
  color: #ffffff;
  min-width: 5rem;
  min-height: 5rem;
}

.glass-blur {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(133, 137, 194, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.hero-image {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url("./ui/img/hero-img.jpg");
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  @apply text-white items-center justify-center;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

#img-about-me {
  filter: grayscale(0.7);
  -webkit-filter: grayscale(0.7);
  max-width: 12rem;
}

#img-rsi-logo {
  max-width: 12rem;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

@layer components {
  .btn {
    @apply shadow-xl py-2 px-3 rounded;
  }

  .btn-primary {
    @apply bg-indigo-500 text-white hover:bg-indigo-400 transition;
  }

  .btn-secondary {
    @apply bg-gray-700 text-white hover:bg-gray-400 transition;
  }

  .btn-yellow {
    @apply bg-yellow-500 text-white hover:bg-yellow-400 transition;
  }
  .nav-links {
    @apply pl-2 py-2 flex items-center text-3xl uppercase font-bold leading-snug text-white hover:opacity-75;
  }
}
